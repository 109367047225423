module.exports = function () {
    var $mobileNav = $('.rf18-mobile-nav'),
        $navItem = $('.rf18-mobile-nav__list-item, .rf18-mobile-nav__language');
    
    if($(window).width() < 800) {
        $('#rf18-open-nav-js').click(function(){
            if($mobileNav.hasClass('open-profile')) {
                $mobileNav.removeClass('open-profile');
                $('body').toggleClass('nav-open');
                
                $('.rf18-mobile-nav__list--language').removeClass('open');    
            } else {
                $('body').toggleClass('nav-open');
                $mobileNav.toggleClass('open');
                
                setTimeout(function(){
                    $('.rf18-mobile-nav__list--language').removeClass('open');    
                }, 300);
                
                $navItem.each(function(){
                    $(this).toggleClass('reveal'); 
                });    
            }
        });
        
        $('#rf18-open-profile-js').click(function(){
            if($mobileNav.hasClass('open')) {
                $mobileNav.removeClass('open');
                
                $navItem.each(function(){
                    $(this).toggleClass('reveal'); 
                });
            }
            
            $('body').toggleClass('nav-open');
            $mobileNav.toggleClass('open-profile');
            $('.rf18-mobile-nav__list--language').removeClass('open');
        });
        
        $(window).scroll(function(){
           if($(window).scrollTop() > 0) {
               $($mobileNav).addClass('scrolling has-scrolled')
           } else {
                $($mobileNav).removeClass('scrolling')
                
                setTimeout(function(){
                    $($mobileNav).removeClass('has-scrolled');
                }, 300);
           }
        });
        
        $(document).ready(function(){
            if($(window).scrollTop() > 0) {
               $($mobileNav).addClass('scrolling has-scrolled')
           } else {
                $($mobileNav).removeClass('scrolling')
                
                setTimeout(function(){
                    $($mobileNav).removeClass('has-scrolled');
                }, 300);
           }
        });
    } else {
        $('.rf18-header__main-item').hover(function(){
           $(this).addClass('open');
           $('.rf18-header').addClass('white');
        }, function() {
            if(!$('.rf18-header').hasClass('scrolling')) {
                $('.rf18-header').removeClass('white');     
            }
           $(this).removeClass('open');
        });
        
        $('.rf18-header__main-item').hover(function(){
           $(this).addClass('open');
           $('.rf18-header').addClass('white');
        }, function() {
            if(!$('.rf18-header').hasClass('scrolling')) {
                $('.rf18-header').removeClass('white');     
            }
           $(this).removeClass('open');
        });
        
        $(window).scroll(function(){
           if($(window).scrollTop() > 0) {
               $('.rf18-header').addClass('white scrolling');
           } else {
                $('.rf18-header').removeClass('white scrolling');
           }
        });
    }
    
    
    // Open language mobile nav
    $('.rf18-mobile-nav__language').click(function(){
        $('.rf18-mobile-nav__list--language').addClass('open');
    });
};