module.exports = function () {
    var feed = new Instafeed({
        get: 'user',
        userId: '1585888975',
        clientId: 'f5eaa5d025174b26a503392624d9b841',
        accessToken: '1585888975.f5eaa5d.2d41348135e3483facd8fd31444b3ad2',
        target: 'rf18-instagram-feed-js',
        resolution: 'standard_resolution',
        template: '<div class="rf18-instagram__slide swiper-slide"><a href="{{link}}"><img src="{{image}}" /></a></div>',
        after: function() {
            if($(window).width() < 600) {
                var instagramSlider = new Swiper ('#rf18-instagram-slider-js', {
                    slidesPerView: 2.2,
                    spaceBetween: 12,
                });  
            } else if ($(window).width() >= 1120) {
                var instagramSlider = new Swiper ('#rf18-instagram-slider-js', {
                    slidesPerView: 8,
                    spaceBetween: 12,
                });
            } else if ($(window).width() < 1120) {
                var instagramSlider = new Swiper ('#rf18-instagram-slider-js', {
                    slidesPerView: 6,
                    spaceBetween: 12,
                });
            } else {
                var instagramSlider = new Swiper ('#rf18-instagram-slider-js', {
                    slidesPerView: 5.2,
                    spaceBetween: 12,
                });
            }
        }
    });
    feed.run();
};