let $ = require('jquery');

//import modules
let video = require('./modules/video.js');
let nav = require('./modules/nav.js');
let input = require('./modules/input.js');
let instagram = require('./modules/instagram.js');
let animations = require('./modules/animations.js');
let swiper = require('./modules/swiper.js');

document.addEventListener('DOMContentLoaded', () => {
  // do your setup here
  video();
  nav();
  input();
  instagram();
  swiper();
  animations();
});