module.exports = function () {
    // Email input animation
    var $emailContainer = $('.rf18-form__email-js');
    
    $emailContainer.focus(function(){
       $(this).parent('.rf18-form__email-container').toggleClass('is-focused');
    });
    
    $emailContainer.focusout(function(){
       $(this).parent('.rf18-form__email-container').toggleClass('is-focused');
    });
};