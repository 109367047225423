module.exports = function () {
    $('.rf18-videoplayer').click(function(){

        if($(this).hasClass('playing')) {
            $(this).find('.rf18-videoplayer__video').get(0).pause();
            $(this).removeClass('playing');
        } else {
            $(this).find('.rf18-videoplayer__video').get(0).play();
            $(this).addClass('playing');
        }
    });
    
    // Check if video has button
    $(document).ready(function(){
        if($('.rf18-introducing').find('.rf18-btn').length > 0) {
            $('.rf18-introducing').addClass('has-button');
        }
    });
};