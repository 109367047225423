module.exports = function () {
    // parralax
    $(document).ready(function(){
        let $parralaxBG = $('.rf18-parralax-bg-js'),
        $parralaxDown = $('.rf18-parralax-down-js'),
        $parralaxUp = $('.rf18-parralax-up-js');
    
        if($(window).width() > 800) {
            $parralaxBG.paroller({
                factor: 0.3,            // multiplier for scrolling speed and offset
                type: 'background',     // background, foreground
                direction: 'vertical' // vertical, horizontal
            });
        
            $parralaxDown.paroller({
                factor: -0.09,
                factorLg: 0.3,// multiplier for scrolling speed and offset
                type: 'foreground',     // background, foreground
                direction: 'vertical' // vertical, horizontal
            });
            
            $parralaxUp.paroller({
                factor: 0.09,            // multiplier for scrolling speed and offset
                type: 'foreground',     // background, foreground
                direction: 'vertical' // vertical, horizontal
            });    
        }
    });
    
    //mask and zoom
    let $maskContainer = $('.rf18-js-mask-and-zoom'),
        windowHeight = $(window).height(),
        windowTop = $(window).scrollTop(),
        windowBottom = windowTop + windowHeight,
        tweeningItemGrid = false,
        tweeningProductSlider = false;
        
    $(document).ready(function(){
        $maskContainer.each(function(){
            let elTop = $(this).offset().top;
            
            if(windowBottom >= elTop) {
                $(this).addClass('in-view');
            } else {
                $(this).removeClass('in-view');
            }
        });
    });
        
    $(window).scroll(function() {
        let windowTop = $(window).scrollTop(),
            windowBottom = windowTop + windowHeight,
            $maskContainer = $('.rf18-js-mask-and-zoom'),
            $titleBlock = $('.rf18-title__block'),
            $itemGrid = $('.rf18-item-grid-js'),
            $productSlider = $('#rf18-product-slider-js');
        
        $maskContainer.each(function(){
            let elTop = $(this).offset().top;
            
            if(windowBottom >= elTop) {
                $(this).addClass('in-view');
            } else {
                $(this).removeClass('in-view');
            }
        })
        
        $titleBlock.each(function(){
            let elTop = $(this).offset().top;
            
            if(windowBottom >= elTop) {
                $(this).addClass('in-view');
            } else {
                $(this).removeClass('in-view');
            }
        })
        
        
        // stagger animation
        function staggerAnimation(el) {
            TweenMax.staggerTo(el, .5, {opacity:1, y:0, delay: 0.2}, 0.2);
        }
        
        function resetAnimation(el) {
            TweenMax.set(el, {opacity:0, y:50});
        }
        
        $itemGrid.each(function(){
            let elTop = $(this).offset().top,
                $itemGridItem = $(this).find('.rf18-item-grid__item');
            
            if(windowBottom >= elTop && !tweeningItemGrid) {
                tweeningItemGrid = true;
                staggerAnimation($itemGridItem)
            } else if(windowBottom < elTop) {
                tweeningItemGrid = false;
                resetAnimation($itemGridItem);
            }
        });
        
        $productSlider.each(function(){
            let elTop = $(this).offset().top,
                $productItem = $(this).find('.rf18-product-slider__slide');
            
            if(windowBottom >= elTop && !tweeningProductSlider) {
                tweeningProductSlider = true;
                staggerAnimation($productItem);
            } else if(windowBottom < elTop) {
                tweeningProductSlider = false;
                resetAnimation($productItem);
            }
        });
    });
    
    
    // Popup
    $(document).ready(function() {
        setTimeout(function(){
            $('.rf18-popup').addClass('show');
        }, 300);
    });
    
    $('.rf18-popup__close').click(function(){
       $('.rf18-popup').removeClass('show');
    });
};